import { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

const TEAM_SIZE_ALL = [-1, 50, 100, 150, 200, 250];

export function formatOrgSizeLabel(size: number | null): string {
  if (!size || size < 0) return 'N/A';
  return `Up to ${size}`;
}

function convertToOption(size: number | null): Option<number | null> {
  return {
    label: formatOrgSizeLabel(size),
    value: size,
  };
}

const TEAM_SIZE_OPTIONS = TEAM_SIZE_ALL.map(convertToOption);

export function OrganizationMaxSizeSelect(props: {
  value: number | null;
  onChange: (value: number | null) => void;
  className?: string;
}): JSX.Element {
  const { value, onChange, className } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<number | null>>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  return (
    <CreatableSelect<Option<number | null>, false>
      value={convertToOption(value)}
      options={TEAM_SIZE_OPTIONS}
      onChange={(v) => v && onChange(v.value)}
      className={className}
      classNamePrefix='select-box-v2'
      styles={styles}
      isMulti={false}
      isValidNewOption={(inputValue) => {
        const num = parseInt(inputValue, 10);
        return !isNaN(num) && num > 0;
      }}
      getNewOptionData={(inputValue) => {
        const value = parseInt(inputValue, 10);
        return {
          label: formatOrgSizeLabel(value),
          value,
        };
      }}
    />
  );
}
