import { useCallback } from 'react';

import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';

export interface triggerDeleteOrganizationModalProps {
  organization: Organization;
  onDelete?: () => void;
}

export function useTriggerDeleteOrganizationModal(): (
  props: triggerDeleteOrganizationModalProps
) => void {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: triggerDeleteOrganizationModalProps) => {
      const confirmCancel = await triggerConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white text-center'>
            <div className='w-full text-2xl font-medium'>
              <p className='text-red-002'>HOLD UP!</p>
              <p>You are about to delete an Organization</p>
            </div>
            <div className='w-full mt-4 text-sms font-normal'>
              Any Organizers and data associated with the Organization will also
              be deleted. This action cannot be undone.
            </div>
          </div>
        ),
        confirmBtnLabel: 'Delete',
        confirmBtnVariant: 'delete',
        cancelBtnLabel: 'Cancel',
      });
      if (confirmCancel.result === 'canceled') return false;

      const resp = await apiService.organization.deleteOrganization(
        props.organization.id
      );
      if (resp) {
        if (props.onDelete) props.onDelete();
      }
    },
    [triggerConfirmCancelModal]
  );
}
